import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

export interface PendingChanges {
  canDeactivate(): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard {
  constructor(private dialog: Dialog) {}

  canDeactivate(
    component: PendingChanges,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean {
    if (component.canDeactivate()) {
      return true;
    }

    this.dialog.open(ConfirmationDialogComponent, {
      width: '700px',
      disableClose: true,
      data: {
        desiredPath: nextState.url,
      },
    });

    return false;
  }
}
